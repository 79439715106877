import React, { Component } from 'react';
import GetLogin from './getLogin';
import Selectbd from './../bd/getDb';
import GetLoginEstudiante from './getLoginEstudiante';
import GetDashboard from './getDashboard';
import GetSeleccionRol from './getSeleccionRol';
import './../css/getApps.css';
//import SideBar from './../layout/sidebar/SideBarMenu'
//import MainContent from './../layout/main/MainContent'
class getApp extends Component {
  constructor(props){
     super(props);
     this.state = {
       load_user : false,
       id_docente:0,
       id_alumno:0,
       id_tipo_rol:0,
       docente:0,
       cedula:'',
       datos_empresa:'',
       year_lectivo:'',
       id_year_lectivo:0,
       pais:'',
       ciudad:'',
       apellidos:'',
       nombres:''
     }
  }
  actualizar_etiquetas =()=>{
    const select = [{
      type: 'consulta', data:
      [{
        table : '(SELECT y.id_year_lectivo,year_lectivo,datos_empresa,pais,ciudad FROM year_lectivo y INNER JOIN datos_empresa d ON d.id_year_lectivo=y.id_year_lectivo WHERE creado=1 ORDER BY y.id_year_lectivo DESC LIMIT 1)',
        field :[],
        return:['year_lectivo','datos_empresa','pais','ciudad','id_year_lectivo']
      }]
    }]

    var result = Selectbd(select)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"){
          this.setState({
            id_year_lectivo:value[1].result[0].id_year_lectivo,
            year_lectivo:value[1].result[0].year_lectivo,
            datos_empresa:value[1].result[0].datos_empresa,
            pais:value[1].result[0].pais,
            ciudad:value[1].result[0].ciudad
          })
        }else{
          console.log("incorrecto");
        }
      });
    }
  }
  validar_login=(id_docente,docente,cedula)=>{
    //console.log("validacion")
    localStorage.setItem("neolav2_escolar_uuid_docente",id_docente)
    localStorage.setItem("neolav2_escolar_docente",docente)
    localStorage.setItem("neolav2_escolar_cedula",cedula)
    this.setState({
      id_docente,docente,cedula,load_user:true,id_tipo_rol:2
    })
  }
  validar_login_estudiante =(apellidos,nombres,cedula,id_alumno)=>{
    localStorage.setItem("neolav2_escolar_apellidos",apellidos)
    localStorage.setItem("neolav2_escolar_nombres",nombres)
    localStorage.setItem("neolav2_escolar_cedula",cedula)
    localStorage.setItem("neolav2_escolar_id_alumno",id_alumno)
    this.setState({
      apellidos,nombres,cedula,id_alumno,load_user:true,id_tipo_rol:1
    })
  }
  get_modulo =(opcion)=>{
    if(opcion==='estudiante'){
      this.setState({
        id_tipo_rol:1
      })
    }else if(opcion==='docente'){
      this.setState({
        id_tipo_rol:2
      })
    }else if(opcion===''){
      this.setState({
        id_tipo_rol:0
      })
    }
  }

  render () {
      const id_tipo_rol =  this.state.id_tipo_rol
      let loading = "Cargando. . ."
      const load_user  = this.state.load_user
      if(load_user===false&&Number(id_tipo_rol)===2){
        loading = <GetLogin validar_login={this.validar_login}  get_modulo={this.get_modulo}/>
      }
      if(load_user===false&&Number(id_tipo_rol)===1){
        loading = <GetLoginEstudiante id_year_lectivo={this.state.id_year_lectivo} validar_login={this.validar_login_estudiante}  get_modulo={this.get_modulo}/>
      }
      if(load_user===false&&Number(id_tipo_rol)===0){
        loading =  <GetSeleccionRol get_modulo={this.get_modulo} />
      }
     if(load_user===true){
        return (<GetDashboard  firebase={this.props.firebase} id_tipo_rol={this.state.id_tipo_rol} apellidos={this.state.apellidos} nombres={this.state.nombres}  cedula={this.state.cedula} docente={this.state.docente} id_docente={this.state.id_docente}/>)
     }else{
       return (
        <div className="grid-container-global">
          <div className="lbl_contenido">{loading}</div>
          <div className="lbl_cabecera">
            <div className="lbl_logo">
              <img style={{"width":"100px"}} src={require('../img/sello.png')}/>
            </div>
            <div className="lbl_datos">
              <p className ="temporal" >{this.state.datos_empresa}</p>
              <p className ="temporal" style={{"fontSize":"15px"}}>Sistema de Notas</p>
              <p className ="temporal" style={{"fontSize":"15px"}}>AÑO EN CURSO: {this.state.year_lectivo}</p>
              <p className ="temporal" style={{"fontSize":"15px"}}>{this.state.pais} - {this.state.ciudad}</p>
            </div>
          </div>
        </div>
     )
   }
  }
  componentDidMount (){
    if(localStorage.getItem("neolav2_escolar_uuid_docente")&&localStorage.getItem("neolav2_escolar_docente")&&localStorage.getItem("neolav2_escolar_cedula")){
      this.validar_login(localStorage.getItem("neolav2_escolar_uuid_docente"),localStorage.getItem("neolav2_escolar_docente"),localStorage.getItem("neolav2_escolar_cedula"));
    }
    if(localStorage.getItem("neolav2_escolar_apellidos")&&localStorage.getItem("neolav2_escolar_nombres")&&localStorage.getItem("neolav2_escolar_cedula")&&localStorage.getItem("neolav2_escolar_id_alumno")){
      this.validar_login_estudiante(localStorage.getItem("neolav2_escolar_apellidos"),localStorage.getItem("neolav2_escolar_nombres"),localStorage.getItem("neolav2_escolar_cedula"),localStorage.getItem("neolav2_escolar_id_alumno"));
    }
    this.actualizar_etiquetas()
  }

}
export default getApp;
