async function Selectbd(objeto){
  var obj
  var url
  if(objeto){
    if(objeto[0].type==='consulta'){
      url= "http://186.4.226.75:8082/neolav2_escolar/public/index.php/database/select";
      obj = { type:'consulta',objeto};
      let data = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(obj),
        headers:{
          'Accept':'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(res => res.json())
      .then((data) => {
         if(data[0].status==="ok"){
           return data
         }else{
           return false
         }
      })
      .catch(console.log)
      return data
    }
    if(objeto[0].type==='insertar'){
      url= "http://186.4.226.75:8082/neolav2_escolar/public/index.php/database/insert";
      obj = { type:'insertar',objeto};
      let data = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(obj),
        headers:{
          'Accept':'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(res => res.json())
      .then((data) => {
         if(data[0].status==="ok"){
           return data
         }else{
           return false
         }
      })
      .catch(console.log)
      return data
    }
    if(objeto[0].type==='actualizar'){
      url= "http://186.4.226.75:8082/neolav2_escolar/public/index.php/database/update";
      obj = { type:'actualizar',objeto};
      let data = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(obj),
        headers:{
          'Accept':'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(res => res.json())
      .then((data) => {
         if(data[0].status==="ok"){
           return data
         }else{
           return false
         }
      })
      .catch(console.log)
      return data
    }
  }
}

export default Selectbd
