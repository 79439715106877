import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import GetButton from './../util/getButton';
import Selectbd from './../bd/getDb';
import GetSelect from './../util/getSelect';
class getExportar extends Component {
  constructor(){/**********/
    super();
    this.state = {
      date_desde : new Date(),
      date_hasta : new Date(),
      btn_exportar_enabled :false,
      id_nivel :0,
      id_docente: Number(localStorage.getItem("neolav2_escolar_uuid_docente")),
      data_nivel :[]
    }
  }

 onChangeDesde = date => this.setState({ date_desde:date })

 onChangeHasta = date => this.setState({ date_hasta:date })

 handleCalendarClose = () => {
   console.log("Calendar closed");
 }
 handleCalendarOpen = () => {
   console.log("Calendar opened");
 }
 return_exportar = ()=>{
   const desde = this.formatDate(this.state.date_desde)
   const hasta = this.formatDate(this.state.date_hasta)

   const id_nivel= this.state.id_nivel
   window.open("https://grupomn.net/neolav2_escolar/public/index.php/export/getreport/"+id_nivel+"/"+desde+"/"+hasta+"/v2")
 }
 return_data =(name,value)=>{
   this.setState({
     [name]:value
   })
 }
 actualizar_select=()=>{
   const id_docente = this.state.id_docente
   const select = [{
     type: 'consulta', data:
     [{
       table:"(SELECT n.id_nivel as id,nivel as name FROM asignacion_leccionario a INNER JOIN nivel n ON n.id_nivel=a.id_nivel AND a.id_docente="+id_docente+" ORDER BY orden)",
       field :[],
       return:['id','name']
     }]
   }]
   var result = Selectbd(select)
   if(result){
     result.then((value) => {
       if(value[1].status==="ok"){
         this.setState({
           data_nivel : value[1].result
         })
       }
     });
   }
 }

 formatDate=(date)=> {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

  render() {
    return (
      <div className="formulario">
        <GetSelect label={"Nivel"} name={"id_nivel"} data={this.state.data_nivel} value={this.state.id_nivel} return_data={this.return_data} />
        <label>Desde</label><DatePicker selected={this.state.date_desde} onChange={this.onChangeDesde} onCalendarClose={this.handleCalendarClose} onCalendarOpen={this.handleCalendarOpen} />
        <label>Hasta</label><DatePicker selected={this.state.date_hasta} onChange={this.onChangeHasta} onCalendarClose={this.handleCalendarClose} onCalendarOpen={this.handleCalendarOpen} />
        <GetButton disabled={this.state.btn_exportar_enabled}  name={"exportar"} value={"Exportar Leccionario"} return_accion={this.return_exportar}  />
      </div>
    )
  }
  componentDidMount (){
    this.actualizar_select()
  }
}

export default getExportar;
