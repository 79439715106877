import React, { Component } from 'react';
import GetInput from './../util/getInput';
import GetButton from './../util/getButton';
import {validate_text} from './../util/getValidate';
import Selectbd from './../bd/getDb';
import './../css/getLogin.css';
class getLogin extends Component {
  constructor(){
     super();
     this.state = {
       load_user : false,
       usuario:'',
       clave:''
     }
  }
  return_data =(name,value)=>{
    this.setState({
      [name]:value
    })
  }
  return_validar_login =(id_docente,docente,cedula)=>{
    this.props.validar_login(id_docente,docente,cedula);
  }
  return_accion_regresar =()=>{
    this.props.get_modulo('')
  }
  return_accion = ()=>{
    const usuario = this.state.usuario
    const clave = this.state.clave
    if(validate_text(usuario)){
      if(validate_text(clave)){
          const select = [{
            type: 'consulta', data:
            [{
              table : '(SELECT docente,cedula,clave,c.id_docente FROM docente c INNER JOIN creacion_docente cr ON cr.id_docente=c.id_docente INNER JOIN creacion_curso cc ON cc.id_creacion_curso=cr.id_curso INNER JOIN (SELECT * FROM year_lectivo WHERE creado=1 ORDER BY id_year_lectivo DESC LIMIT 1) y ON  y.id_year_lectivo=cc.id_year_lectivo GROUP BY c.id_docente)',
              field :[{
                name : 'cedula',
                value :usuario,
                type:'string',
                type_string :'strict'
              },{
                name : 'clave',
                value :clave,
                type:'string',
                type_string :'strict'
              }],
              return:['id_docente','docente','cedula']
            }]
          }]

          var result = Selectbd(select)
          if(result){
            result.then((value) => {
              if(value[1].status==="ok"){
                //console.log("correcto"+value[1].result[0].id_usuario);
                this.return_validar_login(value[1].result[0].id_docente,value[1].result[0].docente,value[1].result[0].cedula)
              }else{
                alert("Usuario/Contraseña Incorrecta")
              }
            });
          }
      }
    }
  }
  render () {
   return (
     <React.Fragment>
      <div  class="grid-container-login-docente">
        <div class="lbl_login">
          <div className="formulario">
           <GetInput label={"Usuario"} name={"usuario"} value={this.state.usuario} return_data={this.return_data} />
           <GetInput label={"Clave"} name={"clave"}  type={"password"} value={this.state.clave} return_data={this.return_data}  />
           <GetButton name={"login"} value={"Ingresar"} return_accion={this.return_accion}  />
           <GetButton name={"regresar"} value={"Regresar"} return_accion={this.return_accion_regresar}  />
          </div>
        </div>
      </div>

     </React.Fragment>
   )
  }

}
export default getLogin;
