import React,{Component} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams
} from "react-router-dom";
import './App.css';
//import GetStatusBd from './bd/getStatusBd';
import GetGlobalContent from './componentes/getGlobalContent';
import firebase from "firebase/app";
import "firebase/storage";
const firebaseConfig = {
  apiKey: "AIzaSyBfMxt2IQ6izSdYmQ-Ie0gKedPwD2PLzwU",
  authDomain: "app-delivery-8b998.firebaseapp.com",
  databaseURL: "https://app-delivery-8b998.firebaseio.com",
  projectId: "app-delivery-8b998",
  storageBucket: "app-delivery-8b998-60x60",
  messagingSenderId: "74232381793",
  appId: "1:74232381793:web:09303378ca795bdeb48520",
  measurementId: "G-9J0C50L1ES"
}
firebase.initializeApp(firebaseConfig)
//const basepath = process.env.PUBLIC_URL
//let dir_url =  "http://localhost/neolav2/index.php/"

class App extends Component {
  render (){
    //var res = basepath.replace("https://www.grupomn.net", "")
    //if(basepath!==''){
  //    dir_url =  basepath.replace("/web", "/api/index.php/")
    //}
    //localStorage.setItem("notificacion_pedido",detalle)
    /*return (
        <Router>
            <Switch>
              <Route path={"/:data_return"} children={<Home/>} />
            </Switch>
        </Router>
    )*/
    return (
      <React.Fragment>
        <GetGlobalContent firebase={firebase}/>
      </React.Fragment>
    )
  }
}
export default App
/* function Home() {
    let { data_return } = useParams();
    if(data_return ==='v2'){
      console.log('Bienvenido a Neola_Escolarv2 :)')
      //if(localStorage.getItem("notificacion_pedido")){
      return (
        <React.Fragment>
          <GetGlobalContent/>
        </React.Fragment>
      )
    }else{
      return (
        <React.Fragment>
         Dirección Errónea
        </React.Fragment>
      )
    }
}*/

/*import React, { useState } from 'react';
import {  BrowserRouter as Router } from 'react-router-dom';
//import logo from './logo.svg';
import './App.scss';
import SideBar from './layout/sidebar/SideBarMenu'
import MainContent from './layout/main/MainContent'
import { GlobalAppContext } from './context';

function App() {
  const [toggled, setToggled] = useState(true);
  const [hasBackground, setHasBackground] = useState(true);

  let style = toggled ? "toggled" : "";
  style += hasBackground ? " sidebar-bg" : "";

  return (
    <Router>
      <GlobalAppContext.Provider
        value={{toggled, setToggled, hasBackground, setHasBackground}}
      >
        <div className={"page-wrapper default-theme bg1 "+ style  }>
          <SideBar />
          <MainContent />
        </div>
      </GlobalAppContext.Provider>
    </Router>
  );
}

export default App;*/
