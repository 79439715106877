import React, { Component } from 'react';
import GetApp from './getApp';
class getGlobalContent extends Component {
  constructor(props){
     super(props);
     this.state = {
       load_config : true
     }
     const basepath = process.env.PUBLIC_URL
     //var res = basepath.replace("https://www.grupomn.net", "")
     let dir_url =  "http://localhost/neolav2_escolar/index.php/"
     if(basepath!==''){
       dir_url =  basepath.replace("/web", "/api/index.php/")
     }
     const load_config = [{
       type: 0, data:
       [{
         api : dir_url,
         name :'default'
       }]
     }]
     localStorage.setItem("api_escolar",dir_url)
     localStorage.setItem("load_config_escolar",load_config)
  }
  f_set_opcion_directa = () =>{

    //this.f_setvalidate();
  }
  f_setvalidate =()=> {
    this.setState({
      load_config:true
    })
  }
  f_seleccion_type_load_config =()=>{
    return (
      <React.Fragment>
       Seleccion tipo de Conexión <br/>
       <button onClick={ () => this.f_set_opcion_directa()}>Directa</button>

      </React.Fragment>
    )
  }
  render () {
   let loading = "Cargando. . ."
   const load_config  = this.state.load_config
   if(load_config===false){
     loading = this.f_seleccion_type_load_config()
   }
   if(load_config===true){
     loading =<GetApp firebase={this.props.firebase}/>
   }
   return (
     <React.Fragment>
     {loading}
     </React.Fragment>
   )
  }
  componentDidMount (){
    if(localStorage.getItem("load_config")){
      this.f_setvalidate();
    }
  }
}
export default getGlobalContent;
