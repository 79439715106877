import React, { Component } from 'react';

class getInput extends Component {
  constructor(props){
     super();
     this.state = {
       name : props.name,
       value : props.value,
       label : props.label,
       type: (props.type)?props.type:'text'
     }
  }
  inputChangedHandler = (event)=>{
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      value
    },()=>{
      this.props.return_data(name,value)
    });
  }

  render () {
   return (
     <React.Fragment>
     <label htmlFor={this.state.name}>{this.state.label}</label><input  type={this.state.type} name={this.state.name}
     id={this.state.name} value={this.state.value} onChange={(event)=>this.inputChangedHandler(event)} />
     </React.Fragment>
   )
  }
  componentDidUpdate(){
    if(this.state.value !== this.props.value){
       this.setState({
        value : this.props.value
      })
    }
  }
}
export default getInput;
