import React from 'react';

import IndexContent from './IndexContent'
import GetLocales from '../../componentes/getLocales'
import GetUsuarios from '../../componentes/getUsuarios'
import GetClase from '../../componentes/getClase'
import GetAsistencia from '../../componentes/getAsistencia'
import GetLink from '../../componentes/getLink'
import GetExportar from '../../componentes/getExportar'
import GetHorarioZoom from '../../componentes/getHorarioZoom'
import GetDocente from '../../componentes/getDocente'
function MainContent(props) {
    let modulo
    if(Number(props.id_modulo)===0){
      modulo =<GetHorarioZoom/>
    }
    if(Number(props.id_modulo)===1){
      modulo =<GetClase/>
    }
    if(Number(props.id_modulo)===2){
      modulo =<GetAsistencia/>
    }
    if(Number(props.id_modulo)===3){
      modulo =<GetLink/>
    }
    if(Number(props.id_modulo)===4){
      modulo =<GetExportar/>
    }
    if(Number(props.id_modulo)===98){
      modulo =<GetDocente  firebase={props.firebase}/>
    }
    if(Number(props.id_modulo)===99){
      localStorage.removeItem("neolav2_escolar_uuid_docente")
      localStorage.removeItem("neolav2_escolar_docente")
      localStorage.removeItem("neolav2_escolar_cedula")
      localStorage.removeItem("neolav2_escolar_apellidos")
      localStorage.removeItem("neolav2_escolar_nombres")
      localStorage.removeItem("neolav2_escolar_id_alumno")
      window.location.reload(false);
    }
    return (
        <main className="page-content">
            <IndexContent />
            <div className="overlay"></div>
            <div className=" routes ">
            {modulo}
            </div>
        </main>
    )
}

export default MainContent;
