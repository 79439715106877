import React, { Component } from 'react';
import GetInput from './../util/getInput';
import GetButton from './../util/getButton';
import GetSelect from './../util/getSelect';
import GetTable from './../util/getTable';
import GetTextArea from './../util/getTextArea';
import GetCheckBox from './../util/getCheckBox';
import Selectbd from './../bd/getDb';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

class getClase extends Component {
  constructor(){/**********/
    super();
    this.state = {
        id_docente:Number(localStorage.getItem("neolav2_escolar_uuid_docente")),
        id_clase: 0,
        modulo:'',
        btn_grabar_enabled:false,
        data_select:[],
        name_select:"",
        observacion_general:'',
        data_asistencia:[],
        data_clases_realizadas:[],
        etiqueta:''
    }
  }
  return_limpiar = ()=>{
    this.setState({
      modulo : '',
      etiqueta:'',
      id_clase: 0,
      btn_grabar_enabled:false,
      observacion_general:'',
      data_asistencia:[]
    })
  }
  return_grabar = ()=>{
    const id_clase = this.state.id_clase
    let etiqueta =''
    if(Number(id_clase)>0){
      this.state.data_select.forEach(cp_CAB => {
          if(Number(id_clase)===Number(cp_CAB.id)){
            etiqueta=cp_CAB.name
          }
      });
      //console.log(this.state.data_select)


      this.setState({
        etiqueta,
        modulo : 'nueva_asistencia',
        btn_grabar_enabled:false
      },()=>{this.return_crear()})
    }else{
      alert("Seleccione Clase")
    }
  }
  return_data =(name,value)=>{
    //console.log(value)
    this.setState({
      [name]:value
    })
  }

  return_crear =()=>{
    const id_clase  = this.state.id_clase
    const select = [{
      type: 'consulta', data:
      [{
        table : "(SELECT c.id_clase,a.id_alumno,apellidos,nombres, 1 as sw_asistencia FROM clase c INNER JOIN creacion_docente cd ON cd.id_creacion_docente=c.id_creacion_docente and c.id_clase="+id_clase+" INNER JOIN matriculacion34 m ON m.id_curso=cd.id_curso INNER JOIN alumno a ON a.id_alumno=m.id_alumno)",
        field :[],
        order_by: "apellidos,nombres",
        return:['id_clase', 'id_alumno', 'apellidos', 'nombres', 'sw_asistencia']
      }]
    }]
    var result = Selectbd(select)
    if(result){
      result.then((value) => {
        //console.log(value)
        if(value[1].status==="ok"){
          this.setState({
            data_asistencia : value[1].result
          })
          value[1].result.map((row, index)=>(
            this.setState({
              ["sw_asistencia_"+index] : 'checked',
              ["observacion"+index] : ''
            })
         ))
        }else{
          this.setState({
            data_asistencia : []
          })
        }
      });
    }

  }
 return_editar =(id_clase)=>{
   console.log(id_clase)
   //const id_clase  = this.state.id_clase
   const select = [{
     type: 'consulta', data:
     [{
       table : "(SELECT id_clase_asistencia_nota,a.id_alumno,apellidos,nombres, sw_asistio,c.observacion FROM clase_asistencia_nota c INNER JOIN alumno a ON a.id_alumno=c.id_alumno WHERE id_clase="+id_clase+")",
       field :[],
       order_by: "apellidos,nombres",
       return:['id_clase_asistencia_nota', 'apellidos', 'nombres', 'sw_asistio','observacion']
     },
     {
       table : "clase",
       field :[{
         name : 'id_clase',
         value : id_clase,
         type:'integer'
       }],
       order_by: "",
       return:['id_clase', 'observacion_general']
     }]
   }]
   var result = Selectbd(select)
   if(result){
     result.then((value) => {
       //console.log(value)
       if(value[1].status==="ok"||value[2].status==="ok"){
         this.setState({
           data_asistencia : value[1].result,
           observacion_general: value[2].result[0].observacion_general
         })
         value[1].result.map((row, index)=>(
           this.setState({
               ["sw_asistencia_"+index] : (Number(row.sw_asistio)===1)?'checked':'',
               ["observacion"+index] : row.observacion
             })
        ))
       }else{
         this.setState({
           data_asistencia : []
         })
       }
     });
   }

 }


 return_actualizar_asistencia=()=>{
   this.setState({
     btn_grabar_enabled:true
   },()=>this.return_actualizar_asistencia_step())
 }

 return_actualizar_asistencia_step = ()=>{
   var items = this.state.data_asistencia;
   if(items){
     items.map((row, index)=>{
       var select = [{
         type: 'actualizar', data:
         [{
           table : 'clase_asistencia_nota',
           field :[{
             name : 'sw_asistio',
             value : (this.state.["sw_asistencia_"+index])?'1':'0',
             type :'integer'
           },{
             name : 'observacion',
             value : (this.state.["observacion"+index])?this.state.["observacion"+index]:'',
             type :'string'
           }],
           id_name : "id_clase_asistencia_nota",
           id_value : row.id_clase_asistencia_nota,
           id_type : "integer",
           return:['id_clase_asistencia_nota']
         }]
       }]
       var result = Selectbd(select)
       if(result){
         result.then((value) => {
           if(value[1].status==="ok"){
           }
         })
       }
     })
     var select = [{
       type: 'actualizar', data:
       [{
         table : 'clase',
         field :[{
           name : 'observacion_general',
           value : this.state.observacion_general,
           type:'string'
         }],
         id_name : "id_clase",
         id_value : this.state.id_clase,
         id_type : "integer",
         return:['id_clase']
       }]
     }]
     var result = Selectbd(select)
     if(result){
       result.then((value) => {
         if(value[1].status==="ok"){
             this.return_limpiar()
             this.actualizar_select()
         }
       })
     }

   }
 }






//id_clase_asistencia_nota, id_clase, id_alumno, sw_asistio, nota, fecha_modificacion
return_grabar_asistencia = ()=>{
    this.setState({
      btn_grabar_enabled:true
    },()=>this.return_grabar_asistencia_step())
}

return_grabar_asistencia_step = ()=>{
  var items = this.state.data_asistencia;
  if(items){
    items.map((row, index)=>{
      var select = [{
        type: 'insertar', data:
        [{
          table : 'clase_asistencia_nota',
          field :[{
            name : 'id_clase',
            value : row.id_clase,
            type:'integer'
          },{
            name : 'id_alumno',
            value : row.id_alumno,
            type :'integer'
          },{
            name : 'sw_asistio',
            value : (this.state.["sw_asistencia_"+index])?'1':'0',
            type :'integer'
          },{
            name : 'observacion',
            value : (this.state.["observacion"+index])?this.state.["observacion"+index]:'',
            type :'string'
          }],
          return:['id_clase_asistencia_nota']
        }]
      }]
      var result = Selectbd(select)
      if(result){
        result.then((value) => {
          if(value[1].status==="ok"){
          }
        })
      }
    })
    var select = [{
      type: 'actualizar', data:
      [{
        table : 'clase',
        field :[{
          name : 'sw_asistencia',
          value : '1',
          type:'integer'
        },{
          name : 'observacion_general',
          value : this.state.observacion_general,
          type:'string'
        }],
        id_name : "id_clase",
        id_value : this.state.id_clase,
        id_type : "integer",
        return:['id_clase']
      }]
    }]
    var result = Selectbd(select)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"){
            this.return_limpiar()
            this.actualizar_select()
        }
      })
    }

  }
}


  actualizar_select=()=>{
    const select = [{
      type: 'consulta', data:
      [{
        table : "(SELECT cl.id_clase as id,cr.id_creacion_docente ,concat(especialidad,'  -  ',nivel,'  -  ',curso,' -  ',materia,' -  ',cast(date_format(fecha_inicio,'%Y-%m-%d') as char),' -  Hora: ',cast( id_hora as char)) as name,id_hora as hora,cast(date_format(fecha_inicio,'%Y-%m-%d') as char) as fecha,bloque,contenido,destreza as id_destreza,cl.eliminado from creacion_docente cr INNER JOIN creacion_curso cc ON cc.id_creacion_curso=cr.id_curso  AND cr.id_docente="+this.state.id_docente+" INNER JOIN clase cl on cl.id_creacion_docente=cr.id_creacion_docente AND cl.sw_asistencia=0 INNER JOIN(SELECT id_year_lectivo FROM year_lectivo WHERE creado=1 ORDER BY id_year_lectivo DESC LIMIT 1) y ON y.id_year_lectivo=cc.id_year_lectivo INNER JOIN nivel n ON n.id_nivel=cc.id_nivel INNER JOIN especialidad e ON e.id_especialidad=cc.id_especialidad INNER JOIN materia m ON m.id_materia=cr.id_materia )",
        field :[{
          name : 'eliminado',
          value : '0',
          type:'integer'
        }],
        order_by: "fecha",
        return:['id', 'name']
      },{
        table : "(SELECT cl.id_clase as id,cr.id_creacion_docente ,concat(especialidad,'  -  ',nivel,'  -  ',curso,' -  ',materia,' -  ',cast(date_format(fecha_inicio,'%Y-%m-%d') as char),' -  Hora: ',cast( id_hora as char)) as name,id_hora as hora,cast(date_format(fecha_inicio,'%Y-%m-%d') as char) as fecha,bloque,contenido,destreza as id_destreza,cl.eliminado from creacion_docente cr INNER JOIN creacion_curso cc ON cc.id_creacion_curso=cr.id_curso  AND cr.id_docente="+this.state.id_docente+" INNER JOIN clase cl on cl.id_creacion_docente=cr.id_creacion_docente AND cl.sw_asistencia=1 INNER JOIN(SELECT id_year_lectivo FROM year_lectivo WHERE creado=1 ORDER BY id_year_lectivo DESC LIMIT 1) y ON y.id_year_lectivo=cc.id_year_lectivo INNER JOIN nivel n ON n.id_nivel=cc.id_nivel INNER JOIN especialidad e ON e.id_especialidad=cc.id_especialidad INNER JOIN materia m ON m.id_materia=cr.id_materia )",
        field :[{
          name : 'eliminado',
          value : '0',
          type:'integer'
        }],
        order_by: "fecha",
        return:['id', 'name']
      }]
    }]
    var result = Selectbd(select)
    if(result){
      result.then((value) => {
        //console.log(value)
        if(value[1].status==="ok"){
          this.setState({
            data_select : value[1].result
          })
        }else{
          this.setState({
            data_select : []
          })
        }
        if(value[2].status==="ok"){
          this.setState({
            data_clases_realizadas: value[2].result
          })
        }else{
          this.setState({
            data_clases_realizadas: []
          })
        }
      });
    }
  }
  getRowsData =()=>{
    var items = this.state.data_asistencia;
    if(items){
      return items.map((row, index)=>{
      return <tr key={index}>
              <td>{index+1}</td>
              <td>{row.apellidos}</td>
              <td>{row.nombres}</td>
              <td><GetCheckBox label={""} name={"sw_asistencia_"+index} value={this.state.["sw_asistencia_"+index]}  return_data={this.return_data} /></td>
              <td><GetInput label={""} name={"observacion"+index} value={this.state.["observacion"+index]}  return_data={this.return_data} /></td>
            </tr>
      })
    }
  }

  return_table_edicion =(data)=>{
    const id_clase = data.id
    if(Number(id_clase)>0){
      this.setState({
        etiqueta:data.name,
        id_clase,
        modulo : 'editar_asistencia',
        btn_grabar_enabled:false
      },()=>{this.return_editar(id_clase)})
    }else{
      alert("Seleccione Clase")
    }
  }
  return_table_eliminar= (data)=>{
    alert("No se puede Eliminar las Asistencias")
  }
  render() {
    const modulo = this.state.modulo
    const etiqueta =this.state.etiqueta
    let formulario

    if(modulo === ''){
      formulario =
      <React.Fragment>
        <GetSelect label={"Clases Disponibles"} name={"id_clase"} data={this.state.data_select} value={this.state.id_clase} return_data={this.return_data} />
        <GetButton disabled={this.state.btn_grabar_enabled}  name={"nuevo"} value={"Nueva Asistencia"} return_accion={this.return_grabar}  />
        <GetTable name={"tabla_clases_realizadas"} data={this.state.data_clases_realizadas}  return_table_edicion={this.return_table_edicion}
         return_table_eliminar={this.return_table_eliminar}  />
      </React.Fragment>
    }
    if(modulo === 'nueva_asistencia'){
      formulario =
      <React.Fragment>
        <table className="table_v2">
        <thead>
         <tr><th>ID</th><th>Apellidos</th><th>Nombres</th><th>Asistencia</th><th>Observación</th></tr>
        </thead>
        <tbody>
         {this.getRowsData()}
        </tbody>
        </table>
        <p><GetTextArea label={"Observación General"} name={"observacion_general"} value={this.state.observacion_general} return_data={this.return_data} /></p>
        <p><GetButton disabled={this.state.btn_grabar_enabled}  name={"nuevo"} value={"Grabar Asistencia"} return_accion={this.return_grabar_asistencia}  /></p>
        <br/><br/><br/><br/>
      </React.Fragment>
    }
    if(modulo === 'editar_asistencia'){
      formulario =
      <React.Fragment>
        <table className="table_v2">
        <thead>
         <tr><th>ID</th><th>Apellidos</th><th>Nombres</th><th>Asistencia</th><th>Observación</th></tr>
        </thead>
        <tbody>
         {this.getRowsData()}
        </tbody>
        </table>
        <p><GetTextArea label={"Observación General"} name={"observacion_general"} value={this.state.observacion_general} return_data={this.return_data} /></p>
        <p><GetButton disabled={this.state.btn_grabar_enabled}  name={"nuevo"} value={"Actualizar Asistencia"} return_accion={this.return_actualizar_asistencia}  /></p>
        <p><GetButton disabled={this.state.btn_grabar_enabled}  name={"nuevo"} value={"Regresar"} return_accion={this.return_limpiar}  /></p>
        <br/><br/><br/><br/>
      </React.Fragment>
    }
    return (
      <React.Fragment>
      {etiqueta}
      {formulario}
      </React.Fragment>
    )
  }
  componentDidMount (){
    this.actualizar_select()
  }

}

export default getClase;
