import React, { Component } from 'react';
import GetInput from './../util/getInput';
import GetButton from './../util/getButton';
import GetSelect from './../util/getSelect';
import GetTable from './../util/getTable';
import GetTextArea from './../util/getTextArea';
import GetCheckBox from './../util/getCheckBox';
import Selectbd from './../bd/getDb';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import GetUploadImage from './getUploadImage';
class getDocente extends Component {
  constructor(){/**********/
    super();
    this.state = {
        id_docente:Number(localStorage.getItem("neolav2_escolar_uuid_docente")),
        direccion:'',
        celular:'',
        telefono:'',
        email:'',
        email_corporativo:'',
        clave:'',
        btn_grabar_enabled:false,
        fecha_nacimiento: new Date(),
        uuid_imagen:''
    }
  }

  onChangeDesde = date => this.setState({ fecha_nacimiento:date })

  onChangeHasta = date => this.setState({ fecha_nacimiento:date })

  handleCalendarClose = () => {
    console.log("Calendar closed");
  }
  handleCalendarOpen = () => {
    console.log("Calendar opened");
  }

  return_data =(name,value)=>{
      this.setState({
        [name]:value
      })
  }

  return_grabar = ()=>{
      this.setState({
        btn_grabar_enabled:true
      },()=>this.return_grabar_asistencia_step())
  }

return_grabar_asistencia_step = ()=>{
    let fecha_nacimiento = this.state.fecha_nacimiento
    fecha_nacimiento= this.formatDate(fecha_nacimiento)
    if(fecha_nacimiento==='1969-12-31'){
      alert("Ingresar Fecha de Nacimiento")
      this.setState({
        btn_grabar_enabled:false
      })
      return null;
    }

    console.log(fecha_nacimiento)
    var select = [{
      type: 'actualizar', data:
      [{
        table : 'docente',
        field :[{
          name : 'direccion',
          value : this.state.direccion,
          type:'string'
        },{
          name : 'celular',
          value : this.state.celular,
          type:'string'
        },{
          name : 'telefono',
          value : this.state.telefono,
          type:'string'
        },{
          name : 'email',
          value : this.state.email,
          type:'string'
        },{
          name : 'email_corporativo',
          value : this.state.email_corporativo,
          type:'string'
        },{
          name : 'clave',
          value : this.state.clave,
          type:'string'
        },{
          name : 'fecha_nacimiento',
          value : this.state.fecha_nacimiento,
          type:'string'
        },{
          name : 'uuid_imagen',
          value : this.state.uuid_imagen,
          type:'string'
        }],
        id_name : "id_docente",
        id_value : this.state.id_docente,
        id_type : "integer",
        return:['id_docente']
      }]
    }]
    var result = Selectbd(select)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"){
           //this.return_limpiar()
           //this.actualizar_select()
           this.setState({
             btn_grabar_enabled:false
           })
        }
      })
    }
}

//id_docente, docente, cedula, fecha_nacimiento, Direccion, id_sector, telefono,
//celular, email, id_tipo_especialidad, estado, observaciones, id_area, fecha_creacion,
//fecha_modificacion, id_creadopor, id_modificadopor, clave, imagen, id_tipo_personal, id_titulo_academico, cursos
//email_corporativo
  actualizar_select=()=>{
    const select = [{
      type: 'consulta', data:
      [{
        table : "(select id_docente,direccion,celular,telefono,email_corporativo,clave,email,cast(date_format(fecha_nacimiento,'%Y') as char) as year,cast(date_format(fecha_nacimiento,'%m') as char) as mes,cast(date_format(fecha_nacimiento,'%d') as char) as dia,uuid_imagen FROM docente d)",
        field :[{
          name : 'id_docente',
          value : this.state.id_docente,
          type: 'integer'
        }],
        order_by: "",
        return:['id_docente', 'direccion', 'celular', 'telefono','email', 'email_corporativo', 'clave','dia','mes','year','uuid_imagen']
      }]
    }]
    var result = Selectbd(select)
    console.log(result)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"){
          this.setState({
            direccion : value[1].result[0].direccion,
            celular : value[1].result[0].celular,
            telefono : value[1].result[0].telefono,
            email : value[1].result[0].email,
            email_corporativo : value[1].result[0].email_corporativo,
            fecha_nacimiento : new Date(value[1].result[0].year, value[1].result[0].mes, value[1].result[0].dia),
            clave : value[1].result[0].clave,
            uuid_imagen : value[1].result[0].uuid_imagen
          })
        }
      });
    }
  }
  formatDate=(date)=> {
     var d = new Date(date),
         month = '' + (d.getMonth() + 1),
         day = '' + d.getDate(),
         year = d.getFullYear();

     if (month.length < 2)
         month = '0' + month;
     if (day.length < 2)
         day = '0' + day;

     return [year, month, day].join('-');
  }

  getUUID =(uuid_imagen,id_item)=>{
    //console.log(uuid_imagen)
    this.setState({
      uuid_imagen
    })
  }

  render() {
    const url_image = "https://firebasestorage.googleapis.com/v0/b/"
    const separator = "/o/images%2F"
    const file_250x200 ="app-delivery-8b998-250x200"
    const token_image250x200 = url_image+file_250x200+separator
    const ext250x200="_250x200.png?alt=media"
    return (
      <React.Fragment>
        <div>
          <div className="formulario">
            <GetInput label={"Dir Domiciliaria"} name={"direccion"}  value={this.state.direccion} return_data={this.return_data} />
            <GetInput label={"Telf Convencional"} name={"telefono"}  value={this.state.telefono} return_data={this.return_data} />
            <GetInput label={"Celular"} name={"celular"}  value={this.state.celular} return_data={this.return_data} />
            <GetInput label={"Email Personal"} name={"email"}   value={this.state.email} return_data={this.return_data}  />
            <GetInput label={"Email Institucional"} name={"email_corporativo"} value={this.state.email_corporativo} return_data={this.return_data} />
            <GetInput label={"Clave"} name={"clave"} value={this.state.clave} return_data={this.return_data} />
            <label>Fch Nacimiento<br/>día/mes/año</label><DatePicker selected={this.state.fecha_nacimiento} onChange={this.onChangeDesde} onCalendarClose={this.handleCalendarClose} onCalendarOpen={this.handleCalendarOpen} />


            <GetUploadImage uuid={1} file_xxx={file_250x200}  firebase={this.props.firebase} getUUID={this.getUUID}
            width={'250px'} height={'200px'} pref={'_250x200'}  token_image={
              (this.state.uuid_imagen)?
              token_image250x200+this.state.uuid_imagen+ext250x200:''
            } />


            <GetButton disabled={this.state.btn_grabar_enabled}  name={"nuevo"} value={"Guardar"} return_accion={this.return_grabar}  />

            </div>
        </div>
      </React.Fragment>
    )
  }
  componentDidMount (){
    this.actualizar_select()
  }

}

export default getDocente;
