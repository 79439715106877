import React from 'react';
import reactLogo from '../../images/logow.png'
function IndexContent() {

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="form-group col-md-12">
                    <h2 className="temporal"> <img  src={require('../../img/sello.png')}  alt="React logo" width="50px" /> Sistema de Notas</h2>
                </div>
            </div>
        </div>
    )
}

export default IndexContent;
