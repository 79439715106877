import React, { Component } from 'react';
import Selectbd from './../bd/getDb';
class getExportar extends Component {
  constructor(){/**********/
    super();
    this.state = {
      date_desde : new Date(),
      id_alumno: Number(localStorage.getItem("neolav2_escolar_id_alumno")),
      data_zoom : []
    }
  }
  formatDate=(date)=> {
     var d = new Date(date),
         month = '' + (d.getMonth() + 1),
         day = '' + d.getDate(),
         year = d.getFullYear();

     if (month.length < 2)
         month = '0' + month;
     if (day.length < 2)
         day = '0' + day;

     return [year, month, day].join('-');
 }

 getRowsData = ()=>{
   var items = this.state.data_zoom;
   if(items){
     return items.map((row, index)=>{
     return <tr key={index}>
             <td>{row.id_hora}</td>
             <td>{row.especialidad}</td>
             <td>{row.nivel}</td>
             <td>{row.curso}</td>
             <td>{row.materia}</td>
             <td><a href ={row.link} target="_blank">Ingresar</a></td>
           </tr>
     })
   }
 }
 actualizar_zoom =()=>{
   const id_alumno  = this.state.id_alumno
   const date_desde = this.state.date_desde
   const date = this.formatDate(date_desde);
   //console.log(date)
   const select = [{
     type: 'consulta', data:
     [{
       table:"((SELECT especialidad,nivel,curso,materia,link,cast('TODO EL DÍA' as char) as id_hora,fecha_inicio FROM clase_zoom z inner join creacion_docente cd on cd.id_creacion_docente =z.id_creacion_docente inner join creacion_curso cr on cr.id_creacion_curso = cd.id_curso inner join nivel n on n.id_nivel=cr.id_nivel and n.id_nivel in(11,12,13,14,15,16) inner join especialidad e on e.id_especialidad=cr.id_especialidad  inner join materia m on m.id_materia=105 inner join matriculacion34 m1 on m1.id_curso=cr.id_creacion_curso and id_alumno="+id_alumno+" and MID(fecha_inicio,1,10)='"+date+"' )UNION(SELECT especialidad,nivel,curso,materia,link,cast(id_hora as char) as id_hora,fecha_inicio FROM clase_zoom z inner join creacion_docente cd on cd.id_creacion_docente =z.id_creacion_docente inner join creacion_curso cr on cr.id_creacion_curso = cd.id_curso inner join nivel n on n.id_nivel=cr.id_nivel   and n.id_nivel in(17,8,9,10,1,2,3)  inner join especialidad e on e.id_especialidad=cr.id_especialidad  inner join materia m on m.id_materia=cd.id_materia inner join matriculacion34 m1 on m1.id_curso=cr.id_creacion_curso and id_alumno="+id_alumno+" and MID(fecha_inicio,1,10)='"+date+"' ))",
       field :[],
       return:['especialidad','nivel','curso','materia','link','id_hora']
     }]
   }]
   var result = Selectbd(select)
   if(result){
     result.then((value) => {
       if(value[1].status==="ok"){
         this.setState({
           data_zoom : value[1].result
         })
       }else{
         this.setState({
           data_zoom : []
         })
       }
     });
   }
 }

  render() {
     const id_alumno  = this.state.id_alumno
     const date = this.formatDate(this.state.date_desde);
     if(id_alumno>0){
        return (
          <div className="formulario">
          Fecha Actual: {date}
            <table className="table_v2">
            <thead>
             <tr><th>Hora</th><th>Especialidad</th><th>Nivel</th><th>Curso</th><th>Materia</th><th>Zoom</th></tr>
            </thead>
            <tbody>
             {this.getRowsData()}
            </tbody>
            </table>
          </div>
        )
      }else{
        return (
          <React.Fragment></React.Fragment>
        )
      }
  }
  componentDidMount (){
    this.actualizar_zoom()
  }

}

export default getExportar;
