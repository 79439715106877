import React, { Component } from 'react';

class getLocales extends Component {
  constructor(){
    super();
    this.state = {
        codigo_local:'',
        nombre_local:'',
        razon_social:'',
        ruc:'',
        id_estado:0,
        id_forma_juridica:0,
        direccion:'',
        id_provincia:0,
        id_parroquia:0,
        id_canton:0,
        sector:'',
        telefono:'',
        id_administrador:0,
        correo_electronico:'',
        id_tipo_local:0,
        id_tipo_precio:0,
        id_tipo_pantalla_tactil:0,
    }
  }
  inputChangedHandler = (event)=>{
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }
  render() {
    return (
      <div>
        Código Local: <input type="textbox" name="codigo_local" value={this.state.codigo_local} onChange={(event)=>this.inputChangedHandler(event)} placeholder="Código Local" /><br/>
        Nombre Local: <input type="textbox" name="nombre_local" value={this.state.nombre_local} onChange={(event)=>this.inputChangedHandler(event)} placeholder="Nombre Local" /><br/>
        Razón Social: <input type="textbox" name="razon_social" value={this.state.razon_social} onChange={(event)=>this.inputChangedHandler(event)} placeholder="Razón Social" /><br/>
        RUC: <input type="textbox" name="ruc" value={this.state.ruc} onChange={(event)=>this.inputChangedHandler(event)} placeholder="RUC" /><br/>
        Estado: <select name="id_estado" value={this.state.id_estado} onChange={(event) =>{this.inputChangedHandler(event)}}>
          <option value="0" >Seleccione una Opción</option>
          <option value="1" >Activo</option>
          <option value="2" >Inactivo</option>
          </select><br/>
        Forma Jurídica: <select value={this.state.new_id_rubro} onChange={(event) =>{this.inputChangedHandler(event)}}>
          <option value="0" >Seleccione una Opción</option>
          <option value="1" >Persona Natural</option>
          <option value="2" >Compañía Limitada</option>
          <option value="3" >Sociedad Anónima</option>
          <option value="4" >Sociedad de Hecho</option>
          </select><br/>
        Provincia: <select value={this.state.id_provincia} onChange={(event) =>{this.inputChangedHandler(event)}}>
          <option value="0" >Seleccione una Opción</option>
          </select><br/>
        Parroquia: <select value={this.state.id_parroquia} onChange={(event) =>{this.inputChangedHandler(event)}}>
          <option value="0" >Seleccione una Opción</option>
          </select><br/>
        Cantón: <select value={this.state.id_canton} onChange={(event) =>{this.inputChangedHandler(event)}}>
          <option value="0" >Seleccione una Opción</option>
          </select><br/>
        Sector: <input type="textbox" name="sector" value={this.state.sector} onChange={(event)=>this.inputChangedHandler(event)} placeholder="Razón Social" /><br/>
        Teléfono: <input type="textbox" name="telefono" value={this.state.telefono} onChange={(event)=>this.inputChangedHandler(event)} placeholder="Razón Social" /><br/>
        Administrador: <select value={this.state.id_administrador} onChange={(event) =>{this.inputChangedHandler(event)}}>
          <option value="0" >Seleccione una Opción</option>
          <option value="1" >Restaurante</option>
          <option value="2" >Centro Comercial</option>
          </select><br/>
        Correo Electrónico: <input type="textbox" name="correo_electronico" value={this.state.correo_electronico} onChange={(event)=>this.inputChangedHandler(event)} placeholder="Razón Social" /><br/>
        Administrador: <select value={this.state.id_tipo_local} onChange={(event) =>{this.inputChangedHandler(event)}}>
          <option value="0" >Seleccione una Opción</option>
          <option value="1" >Restaurante</option>
          <option value="2" >Centro Comercial</option>
          </select><br/>
        Tipo de Local: <select value={this.state.id_tipo_local} onChange={(event) =>{this.inputChangedHandler(event)}}>
            <option value="0" >Seleccione una Opción</option>
            <option value="1" >Restaurante</option>
            <option value="2" >Centro Comercial</option>
            </select><br/>
        Pantalla Táctil: <select value={this.state.id_tipo_pantalla_tactil} onChange={(event) =>{this.inputChangedHandler(event)}}>
          <option value="0" >Seleccione una Opción</option>
          <option value="1" >Restaurante</option>
          <option value="2" >Centro Comercial</option>
          </select><br/>
      </div>
    )
  }
}

export default getLocales;
