import React, { Component } from 'react';
import './../css/getSeleccionRol.css';
class getSeleccionRol extends Component {
//style={{"opacity":"0.6","backgroundImage": `url(${require('../img/fondo.jpg')})`,"backgroundSize": "cover"}}
  render () {
   return (
     <div  className="grid-container-seleccion">
       <div className="lbl_etiqueta_seleccion  temporal" style={{"fontSize":"15px"}}><b>Seleccione Tipo de Rol:</b></div>
       <div className="lbl_opcion_1 temporal" style={{"fontSize":"15px","backgroundImage": `url(${require('../img/estudiante.jpg')})`,"backgroundSize": "cover"}}  onClick={ () => this.props.get_modulo("estudiante")}>Estudiante / Padre de Familia</div>
       <div className="lbl_opcion_2 temporal" style={{"fontSize":"15px","backgroundImage": `url(${require('../img/docente.jpg')})`,"backgroundSize": "cover"}}  onClick={ () => this.props.get_modulo("docente")}>Docente</div>
     </div>
   )
  }

}
export default getSeleccionRol;
