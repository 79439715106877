import React, { Component } from 'react';
import GetInput from './../util/getInput';
import GetButton from './../util/getButton';
import GetSelect from './../util/getSelect';
import GetTable from './../util/getTable';
import GetTextArea from './../util/getTextArea';
import Selectbd from './../bd/getDb';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

class getClase extends Component {
  constructor(){/**********/
    super();
    this.state = {
        id_clase:0,
        id_docente: Number(localStorage.getItem("neolav2_escolar_uuid_docente")),
        id_creacion_docente:0,
        fecha_busqueda: '',
        btn_grabar_enabled:false,
        modulo:'',
        data_asignacion_docente:[],
        data_hora:[],
        data_bloque:[],
        bloque:'',
        contenido:'',
        destreza:'',
        id_hora:-1,
        date: new Date(),
        data:[]
    }
  }
  return_limpiar = ()=>{
    this.setState({
      modulo : '',
      id_clase: 0,
      id_creacion_docente:0,
      btn_grabar_enabled:false,
      bloque:'',
      contenido:'',
      destreza:'',
      id_hora:-1,
      date: new Date()
    })
  }
  return_table_edicion = (data)=>{
    var fecha =data.fecha.split('-');
    var mydate = new Date(fecha[0], fecha[1] - 1, fecha[2]);
    //console.log(mydate.toDateString());

    this.setState({
      modulo : 'nueva_clase',
      id_clase: data.id_clase,
      id_creacion_docente:data.id_creacion_docente,
      btn_grabar_enabled:false,
      bloque:data.bloque,
      contenido:data.contenido,
      destreza:data.id_destreza,
      id_hora:Number(data.hora),
      date: mydate
    })
  }
  return_grabar = ()=>{
    this.setState({
      modulo : 'nueva_clase',
      id_clase: 0,
      id_creacion_docente:0,
      btn_grabar_enabled:false,
      bloque:'',
      contenido:'',
      destreza:'',
      id_hora:-1,
      date: new Date()
    })
  }
  return_data =(name,value)=>{
    this.setState({
      [name]:value
    })
  }
 onChange = date => this.setState({ date })

 formatDate=(date)=> {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

 return_crear =()=>{
   const id_docente = Number(this.state.id_docente)
   const id_creacion_docente = Number(this.state.id_creacion_docente)
   const id_hora= Number(this.state.id_hora)
   const bloque =this.state.bloque
   const contenido = this.state.contenido
   const destreza = this.state.destreza
   const date = this.formatDate(this.state.date)

   if(id_creacion_docente===0){
     alert("Seleccione Materia")
     return null
   }
   if(id_hora<=0){
     alert("Seleccione Hora")
     return null
   }
   if(bloque.trim()===''){
     alert("Ingrese Bloque")
     return null
   }
   if(contenido.trim()===''){
     alert("Ingrese Contenido")
     return null
   }
   if(destreza.trim()===''){
     alert("Ingrese Destreza")
     return null
   }
   this.setState({
     btn_grabar_enabled:true
   },()=>this.grabar(id_docente,id_creacion_docente,bloque,contenido,destreza,id_hora,date))

 }
 grabar = (id_docente,id_creacion_docente,bloque,contenido,destreza,id_hora,date)=>{
   //id_clase,  id_tipo_clase, id_creacion_curso, sw_tarea,
   // fecha_fin, eliminado,   id_hora, bloque, contenido, destreza, id_creacion_docente
   const id_clase  = this.state.id_clase
   var select
   if(Number(id_clase)===0){
     select = [{
       type: 'insertar', data:
       [{
         table : 'clase',
         valide_duplicate:['id_docente','fecha_inicio','id_creacion_docente','eliminado','id_hora','bloque'],
         field :[{
           name : 'id_docente',
           value : id_docente,
           type:'integer'
         },{
           name : 'eliminado',
           value : '0',
           type:'integer'
         },{
           name : 'fecha_inicio',
           value : date,
           type :'string'
         },{
           name : 'id_hora',
           value : id_hora,
           type :'integer'
         },{
           name : 'bloque',
           value : bloque,
           type :'integer'
         },{
           name : 'contenido',
           value : contenido,
           type :'string'
         },{
           name : 'destreza',
           value : destreza,
           type :'string'
         },{
           name : 'id_creacion_docente',
           value : id_creacion_docente,
           type :'integer'
         }],
         return:['id_clase']
       }]
     }]
   }else{
     select = [{
       type: 'actualizar', data:
       [{
         table : 'clase',
         field :[{
           name : 'id_docente',
           value : id_docente,
           type:'integer'
         },{
           name : 'fecha_inicio',
           value : date,
           type :'string'
         },{
           name : 'id_hora',
           value : id_hora,
           type :'integer'
         },{
           name : 'bloque',
           value : bloque,
           type :'string'
         },{
           name : 'contenido',
           value : contenido,
           type :'string'
         },{
           name : 'destreza',
           value : destreza,
           type :'string'
         },{
           name : 'id_creacion_docente',
           value : id_creacion_docente,
           type :'integer'
         }],
         id_name : "id_clase",
         id_value : id_clase,
         id_type : "integer",
         return:['id_clase']
       }]
     }]
   }
   var result = Selectbd(select)
   //console.log(result)
   if(result){
     result.then((value) => {
       if(value[1].status==="ok"){
         this.actualizar_tabla()
         this.return_limpiar()
       }
       if(value[1].status==="duplicate"){
         alert("Clase ya registrada")
         this.setState({
           btn_grabar_enabled:false,
           })
       }
     })
   }
 }

 actualizar_tabla=()=>{
   const select = [{
     type: 'consulta', data:
     [{
       table : "(SELECT cl.id_clase,cr.id_creacion_docente ,concat(especialidad,'  -  ',nivel,'  -  ',curso,' -  ',materia) as name,id_hora as hora,cast(date_format(fecha_inicio,'%Y-%m-%d') as char) as fecha,bloque,contenido,destreza as id_destreza,cl.eliminado from creacion_docente cr INNER JOIN creacion_curso cc ON cc.id_creacion_curso=cr.id_curso  AND cr.id_docente="+this.state.id_docente+" INNER JOIN clase cl on cl.id_creacion_docente=cr.id_creacion_docente INNER JOIN(SELECT id_year_lectivo FROM year_lectivo WHERE creado=1 ORDER BY id_year_lectivo DESC LIMIT 1) y ON y.id_year_lectivo=cc.id_year_lectivo INNER JOIN nivel n ON n.id_nivel=cc.id_nivel INNER JOIN especialidad e ON e.id_especialidad=cc.id_especialidad INNER JOIN materia m ON m.id_materia=cr.id_materia )",
       field :[{
         name : 'eliminado',
         value : '0',
         type:'integer'
       }],
       order_by: "fecha",
       return:['id_clase', 'id_creacion_docente', 'name', 'hora', 'fecha', 'bloque', 'contenido', 'id_destreza']
     }]
   }]
   // ORDER BY fecha_inicio desc,n.orden,curso,materia
   var result = Selectbd(select)
   if(result){
     result.then((value) => {
       if(value[1].status==="ok"){
         this.setState({
           data : value[1].result
         })
       }else{
         this.setState({
           data :[]
         })
       }
     });
   }
 }

 /*((SELECT cr.id_creacion_docente as id,concat(especialidad,'  -  ',nivel,'  -  ',curso,' -  ',materia) as name from (select * from creacion_docente where id_curso in( select id_curso from creacion_docente where id_docente="+this.state.id_docente+" and si_dirigente=1)) cr INNER JOIN creacion_curso cc ON cc.id_creacion_curso=cr.id_curso AND cr.id_docente="+this.state.id_docente+"  INNER JOIN(SELECT id_year_lectivo FROM year_lectivo WHERE creado=1 ORDER BY id_year_lectivo DESC LIMIT 1) y ON y.id_year_lectivo=cc.id_year_lectivo  INNER JOIN nivel n ON n.id_nivel=cc.id_nivel and n.id_nivel in(11,12,13,14,15,16) INNER JOIN especialidad e ON e.id_especialidad=cc.id_especialidad INNER JOIN materia m ON m.id_materia=cr.id_materia   ORDER BY n.orden,curso,materia)union(SELECT cr.id_creacion_docente as id,concat(especialidad,'  -  ',nivel,'  -  ',curso,' -  ',materia) as name from  creacion_docente cr INNER JOIN creacion_curso cc ON cc.id_creacion_curso=cr.id_curso AND cr.id_docente="+this.state.id_docente+"  INNER JOIN(SELECT id_year_lectivo FROM year_lectivo WHERE creado=1 ORDER BY id_year_lectivo DESC LIMIT 1) y ON y.id_year_lectivo=cc.id_year_lectivo  INNER JOIN nivel n ON n.id_nivel=cc.id_nivel and n.id_nivel in(17,8,9,10,1,2,3) INNER JOIN especialidad e ON e.id_especialidad=cc.id_especialidad INNER JOIN materia m ON m.id_materia=cr.id_materia ORDER BY n.orden,curso,materia))*/
/*(SELECT cl.id_clase_zoom,cr.id_creacion_docente as id ,concat(especialidad,'  -  ',nivel,'  -  ',curso,' -  ',materia) as name,cast(date_format(fecha_inicio,'%Y-%m-%d') as char) as fecha,link,id_hora as hora,cl.eliminado from creacion_docente cr INNER JOIN creacion_curso cc ON cc.id_creacion_curso=cr.id_curso  AND cr.id_docente="+this.state.id_docente+" INNER JOIN clase_zoom cl on cl.id_creacion_docente=cr.id_creacion_docente INNER JOIN(SELECT id_year_lectivo FROM year_lectivo WHERE creado=1 ORDER BY id_year_lectivo DESC LIMIT 1) y ON y.id_year_lectivo=cc.id_year_lectivo INNER JOIN nivel n ON n.id_nivel=cc.id_nivel INNER JOIN especialidad e ON e.id_especialidad=cc.id_especialidad INNER JOIN materia m ON m.id_materia=cr.id_materia )*/
  actualizar_select=()=>{//UNION(SELECT 7 AS ID, 7 as NAME)//UNION(SELECT 4 AS ID, 4 as NAME)
    const select = [{
      type: 'consulta', data:
      [{
        table:"(SELECT cr.id_creacion_docente as id,concat(especialidad,'  -  ',nivel,'  -  ',curso,' -  ',materia) as name from  creacion_docente cr INNER JOIN creacion_curso cc ON cc.id_creacion_curso=cr.id_curso AND cr.id_docente="+this.state.id_docente+"  INNER JOIN(SELECT id_year_lectivo FROM year_lectivo WHERE creado=1 ORDER BY id_year_lectivo DESC LIMIT 1) y ON y.id_year_lectivo=cc.id_year_lectivo  INNER JOIN nivel n ON n.id_nivel=cc.id_nivel   INNER JOIN especialidad e ON e.id_especialidad=cc.id_especialidad INNER JOIN materia m ON m.id_materia=cr.id_materia ORDER BY n.orden,curso,materia)",
        field :[],
        return:['id','name']
      },{
        table : "((SELECT 1 AS ID, 1 as NAME)UNION(SELECT 2 AS ID, 2 as NAME)UNION(SELECT 3 AS ID, 3 as NAME)UNION(SELECT 5 AS ID, 5 as NAME)UNION(SELECT 6 AS ID, 6 as NAME)UNION(SELECT 8 AS ID, 8 as NAME)UNION(SELECT 9 AS ID, 9 as NAME))",
        field :[],
        order_by:'ID',
        return:['id','name']
      },{
        table : "((SELECT 1 AS ID, 1 as NAME)UNION(SELECT 2 AS ID, 2 as NAME)UNION(SELECT 3 AS ID, 3 as NAME)UNION(SELECT 4 AS ID, 4 as NAME))",
        field :[],
        order_by:'ID',
        return:['id','name']
      }]
    }]
    var result = Selectbd(select)
    if(result){
      result.then((value) => {
        if(value[1].status==="ok"&&value[2].status==="ok"){
          this.setState({
            data_asignacion_docente : value[1].result,
            data_hora : value[2].result,
            data_bloque : value[3].result
          })
        }
      });
    }
  }
 return_table_eliminar =()=>{
   alert("Opción Deshabilitada")
 }
  render() {
    const modulo = this.state.modulo
    let formulario




    if(modulo === ''){
      formulario =
      <React.Fragment>
        <GetButton disabled={this.state.btn_grabar_enabled}  name={"nuevo"} value={"Nueva Clase"} return_accion={this.return_grabar}  />
        <GetTable name={"tabla_usuario"} data={this.state.data}  return_table_edicion={this.return_table_edicion}
         return_table_eliminar={this.return_table_eliminar}  />
      </React.Fragment>
    }
    if(modulo === 'nueva_clase'){
      formulario =
      <React.Fragment>
        <div style={{"display": "inline-flex"}}>
          <div>
            <div className="formulario">
              <GetSelect label={"Materia"} name={"id_creacion_docente"} data={this.state.data_asignacion_docente} value={this.state.id_creacion_docente} return_data={this.return_data} />
              <GetSelect label={"Hora"} name={"id_hora"} data={this.state.data_hora} value={this.state.id_hora} return_data={this.return_data} />
              <GetSelect label={"Bloque"} name={"bloque"} data={this.state.data_bloque} value={this.state.bloque} return_data={this.return_data} />
              <GetTextArea label={"Contenido"} name={"contenido"}   value={this.state.contenido} return_data={this.return_data}  />
              <GetTextArea label={"Destreza"} name={"destreza"} value={this.state.destreza} return_data={this.return_data} />
              <GetButton disabled={this.state.btn_grabar_enabled}  name={"nuevo"} value={"Guardar"} return_accion={this.return_crear}  />
              <GetButton disabled={this.state.btn_grabar_enabled}  name={"limpiar"} value={"Regresar"} return_accion={this.return_limpiar}  />
            </div>
          </div>
          <div>
            <Calendar onChange={this.onChange} format={"yyyy-MM-dd"} value={this.state.date} />
          </div>
        </div>

      </React.Fragment>
    }
    return (
      <React.Fragment>
      {formulario}
      </React.Fragment>
    )
  }
  componentDidMount (){
    this.actualizar_select()
    this.actualizar_tabla()
  }

}

export default getClase;
