import React, { Component } from 'react';

const RenderRow = (props) =>{
 return props.keys.map((key, index)=>{
 return key.toUpperCase().includes('ID_')?null:<td key={props.data[key]+"_"+index}>{props.data[key]}</td>
 })
}

class getTable extends Component {
  constructor(props){
     super();
     this.state = {
       name : props.name,
       data : props.data,
       label : props.label

     }
  }
getKeys =()=>{
  if(this.props.data[0]){
    return Object.keys(this.props.data[0]);
  }else{
    return false
  }
}
getHeader =()=>{
   var keys = this.getKeys();
   if(keys){
     return keys.map((key, index)=>{
       return key.toUpperCase().includes('ID_')?null:<th key={key}>{key.toUpperCase()}</th>
     })
   }
 }
 getRowsData= ()=>{
    var items = this.props.data;
    var keys = this.getKeys();
    if(keys){
      return items.map((row, index)=>{
      return <tr key={index}><RenderRow key={index} data={row} keys={keys}/><td onClick={ () => this.props.return_table_edicion(row)}>Editar</td><td onClick={ () => this.props.return_table_eliminar(row)}>Eliminar</td></tr>
      })
    }
 }
  render () {
   //const data = this.props.data ?   this.props.data :[]
   //console.log(data)
   return (
     <React.Fragment>
     <table className="table_v2">
     <thead>
      <tr>{this.getHeader()}</tr>
     </thead>
     <tbody>
      {this.getRowsData()}
     </tbody>
     </table>
     </React.Fragment>
   )
  }


}
export default getTable;
