import React, { Component } from 'react';
//import logo from './logo.svg';
import './../App.scss';
import SideBar from './../layout/sidebar/SideBarMenu'
import MainContent from './../layout/main/MainContent'


class getDashboard extends Component {
  constructor(props){
     super(props);
     this.state = {
       id_modulo:0
     }
  }

  getModulo =(id_modulo)=>{
    this.setState({
      id_modulo
    })
  }
  render() {

    let style =  "toggled"  ;
    style +=  " sidebar-bg"  ;
    return (
          <div className={"page-wrapper default-theme bg1 "+ style  }>
            <SideBar getModulo={this.getModulo} apellidos={this.props.apellidos} nombres={this.props.nombres} docente={this.props.docente} cedula={this.props.cedula} id_tipo_rol={this.props.id_tipo_rol} />
            <MainContent  firebase={this.props.firebase} id_modulo={this.state.id_modulo} />
          </div>
    )


    }
  }
  export default getDashboard;
