import React, { Component } from 'react';

class getButton extends Component {
  constructor(props){
     super();
     this.state = {
       name : props.name,
       value : props.value,
       disabled : (props.disabled)?props.disabled: false
     }
  }
  action = ()=>{
   this.props.return_accion();
  }

  render () {
   return (
     <React.Fragment>
       <button disabled={this.state.disabled} onClick={ () => this.action()}>
        {this.state.value}
       </button>
     </React.Fragment>
   )
  }

  componentDidUpdate(){
    if(this.state.disabled !== this.props.disabled){
       this.setState({
        disabled : this.props.disabled
      })
    }
  }

}
export default getButton;
