import React, { Component } from 'react';

class getSelect extends Component {
  constructor(props){
     super();
       //console.log("Carga2 "+ props.data)
     this.state = {
       name : props.name,
       value : props.value,
       label : props.label

     }
  }
  inputChangedHandler = (event)=>{
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      value
    },()=>{
      this.props.return_data(name,value)
    });
  }

  render () {
   const data = this.props.data ?   this.props.data :[]
   return (
     <React.Fragment>
          <label htmlFor={this.state.name}>{this.state.label}</label><select name={this.state.name} id={this.state.name} value={this.state.value} onChange={(e) => {this.inputChangedHandler(e)}}>
          <option value="-1">Seleccione {this.state.label}</option>
          {(data) ?
            data.map(row => (
              <option key={row.id} value={row.id} >{row.name}</option>
            ))
            : <React.Fragment></React.Fragment>
          }
      </select>
     </React.Fragment>
   )
  }
  componentDidUpdate(){
    if(this.state.value !== this.props.value){
       this.setState({
        value : this.props.value
      })
    }
  }

}
export default getSelect;
