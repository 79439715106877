import React, { Component } from 'react';
import GetInput from './../util/getInput';
import GetButton from './../util/getButton';
import {validate_text} from './../util/getValidate';
import Selectbd from './../bd/getDb';
import './../css/getLogin.css';
class getLoginEstudiante extends Component {
  constructor(){
     super();
     this.state = {
       load_user : false,
       usuario:''
     }
  }
  return_data =(name,value)=>{
    this.setState({
      [name]:value
    })
  }
  return_validar_login =(apellidos,nombres,cedula,id_alumno)=>{
    this.props.validar_login(apellidos,nombres,cedula,id_alumno);
  }
  return_accion_regresar =()=>{
    this.props.get_modulo('')
  }
  return_accion = ()=>{
    const usuario = this.state.usuario
    if(validate_text(usuario)){
        const select = [{
            type: 'consulta', data:
            [{
              table : '(SELECT apellidos,nombres,cedula,c.id_alumno FROM alumno c INNER JOIN matriculacion34 cr ON cr.id_alumno=c.id_alumno )',
              field :[{
                name : 'cedula',
                value :usuario,
                type:'string',
                type_string :'strict'
              }],
              return:['apellidos','nombres','cedula','id_alumno']
            }]
          }]

          var result = Selectbd(select)
          if(result){
            result.then((value) => {
              if(value[1].status==="ok"){
                this.return_validar_login(value[1].result[0].apellidos,value[1].result[0].nombres,value[1].result[0].cedula,value[1].result[0].id_alumno)
              }else{
                console.log("incorrecto");
              }
            });
          }
    }
  }
  render () {
   return (
     <React.Fragment>
      <div class="grid-container-login-docente">
        <div class="lbl_login">
          <div className="formulario">
           <GetInput label={"Cédula"} name={"usuario"} value={this.state.usuario} return_data={this.return_data} />
           <GetButton name={"login"} value={"Ingresar"} return_accion={this.return_accion}  />
           <GetButton name={"regresar"} value={"Regresar"} return_accion={this.return_accion_regresar}  />
          </div>
        </div>
      </div>
     </React.Fragment>
   )
  }

}
export default getLoginEstudiante;
